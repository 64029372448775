import moment from 'moment';

export class Allowance {
  constructor(data) {
    this.data = data;

    this.isUpdatable = function () {
      if (!this.firstDate) return false;

      return this.secondDateIsSameOrAfter || this.firstDateIsSameOrAfter;
    };

    this.formattedDatesStr = function () {
      if (!this.firstDate) return "";
      if (!this.secondDate) return this.firstDate.format(this.dateFormat);

      return `${this.firstDateFmt} e ${this.secondDateFmt}`;
    };

    this.dateFormat = "DD/MM/YY";

    this.firstDate = this.data.first_payment_date &&
      moment(this.data.first_payment_date) ||
      null;

    this.firstDateFmt = this.firstDate?.format(this.dateFormat);

    this.secondDate = this.data.second_payment_date &&
      moment(this.data.second_payment_date) ||
      null;

    this.secondDateFmt = this.secondDate?.format(this.dateFormat);

    this.currentDate = moment().startOf('day');

    this.firstDateIsSameOrAfter = this.firstDate?.startOf('day')
      .isSameOrAfter(this.currentDate);

    this.secondDateIsSameOrAfter = this.secondDate?.startOf('day')
      .isSameOrAfter(this.currentDate);
  }
}