/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import i18n from "#translate/i18n";
import groupEventsByDate from '#utils/groupEventsByDate';
import StartedInCompany from '#components/user/profile/historicTab/StartedInCompany';
import RemovalConfirmation from '#components/user/profile/RemovalConfirmation';

import NewUserCreatedByAdmin from '#components/user/profile/historicTab/NewUserCreatedByAdmin';
import DismissalCreated from '#components/user/profile/historicTab/DismissalCreated';
import DismissalUpdated from '#components/user/profile/historicTab/DismissalUpdated';
import SalaryUpdateRequestCreated from '#components/user/profile/historicTab/SalaryUpdateRequestCreated';
import SalaryUpdateRequestRemoved from '#components/user/profile/historicTab/SalaryUpdateRequestRemoved';
import SalaryUpdateRequestComplete from '#components/user/profile/historicTab/SalaryUpdateRequestComplete';
import SalaryUpdateRequestUpdated from '#components/user/profile/historicTab/SalaryUpdateRequestUpdated';
import UserRegisterUpdated from '#components/user/profile/historicTab/UserRegisterUpdated';
import ExpenseAssistanceCreated from '#components/user/profile/historicTab/ExpenseAssistanceCreated';
import ExpenseAssistanceRemoved from '#components/user/profile/historicTab/ExpenseAssistanceRemoved';
import ExpenseAssistanceUpdated from '#components/user/profile/historicTab/ExpenseAssistanceUpdated';
import VacationCreated from '#components/user/profile/historicTab/VacationCreated';
import VacationUpdated from '#components/user/profile/historicTab/VacationUpdated';
import VacationCreditPublished from '#components/user/profile/historicTab/VacationCreditPublished'
import ParentalLeaveCreated from '#components/user/profile/historicTab/ParentalLeaveCreated';
import ParentalLeaveUpdated from '#components/user/profile/historicTab/ParentalLeaveUpdated';
import ParentalLeaveStart from '#components/user/profile/historicTab/ParentalLeaveStart';
import ParentalLeaveReturn from '#components/user/profile/historicTab/ParentalLeaveReturn';
import ParentalLeaveRemoved from '#components/user/profile/historicTab/ParentalLeaveRemoved';
import VacationStart from '#components/user/profile/historicTab/VacationStart';
import VacationReturn from '#components/user/profile/historicTab/VacationReturn';
import VacationRemoved from '#components/user/profile/historicTab/VacationRemoved';
import BonusRewardCreated from '#components/user/profile/historicTab/BonusRewardCreated';
import BonusRewardRemoved from '#components/user/profile/historicTab/BonusRewardRemoved';
import BonusRewardUpdated from '#components/user/profile/historicTab/BonusRewardUpdated';
import HomeOfficeCreated from '#components/user/profile/historicTab/HomeOfficeCreated';
import HomeOfficeRemoved from '#components/user/profile/historicTab/HomeOfficeRemoved';
import HomeOfficeUpdated from '#components/user/profile/historicTab/HomeOfficeUpdated';
import MealCreated from '#components/user/profile/historicTab/MealCreated';
import MealRemoved from '#components/user/profile/historicTab/MealRemoved';
import MealUpdated from '#components/user/profile/historicTab/MealUpdated';
import PsychologicalSupportCreated from '#components/user/profile/historicTab/PsychologicalSupportCreated';
import PsychologicalSupportRemoved from '#components/user/profile/historicTab/PsychologicalSupportRemoved';
import PsychologicalSupportUpdated from '#components/user/profile/historicTab/PsychologicalSupportUpdated';
import YearEndBonusCreated from '#components/user/profile/historicTab/YearEndBonusCreated';
import YearEndBonusUpdated from '#components/user/profile/historicTab/YearEndBonusUpdated';
import YearEndBonusRemoved from '#components/user/profile/historicTab/YearEndBonusRemoved';
import HealthcareCreated from '#components/user/profile/historicTab/HealthcareCreated';
import HealthcareUpdated from '#components/user/profile/historicTab/HealthcareUpdated';
import HealthcareRemoved from '#components/user/profile/historicTab/HealthcareRemoved';
import HealthcareCreditPublished from '#components/user/profile/historicTab/HealthcareCreditPublished';
import WorkLeaveCreated from '#components/user/profile/historicTab/WorkLeaveCreated';
import WorkLeaveUpdated from '#components/user/profile/historicTab/WorkLeaveUpdated';
import WorkLeaveRemoved from '#components/user/profile/historicTab/WorkLeaveRemoved';
import WorkLeaveStart from '#components/user/profile/historicTab/WorkLeaveStart';
import WorkLeaveReturn from '#components/user/profile/historicTab/WorkLeaveReturn';

import removeBonusReward from '#requests/userProfile/removals/removeBonusReward'
import removeSalaryUpdateRequest from '#requests/userProfile/removals/removeSalaryUpdateRequest';
import removeAllowance from '#requests/userProfile/removals/removeAllowance';
import removeExpenseAssistance from '#requests/userProfile/removals/removeExpenseAssistance';
import removeWorkLeave from '#requests/userProfile/removals/removeWorkLeave';

import { UserContext } from "#providers/UserContextProvider"

export default function Timeline({ userEventsData, userData, handleOpenedDrawersFromProfile }) {
  const { refreshUserData, setRefreshUserData, objectId, setObjectId, setShowMessage } = useContext(UserContext)
  const [openRemovalConfirmation, setOpenRemovalConfirmation] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [chosenEventRemoval, setChosenEventRemoval] = useState()
  const [isDismissed, setIsDismissed] = useState(false)
  const pluck = (arr, key) => arr.map(i => i[key]);
  const groupedByDate = groupEventsByDate(userEventsData);
  const eventsWithIdentifiers = userEventsData.map((event, index) => {
    event['identifier'] = `identifier_${index}`
    return event
  })

  const timelineEventsData = Object.create(eventsWithIdentifiers).reverse()
  const filteredIdentifiers = (eventName) => {
    const filteredEvents = timelineEventsData.filter((event) => event.name === eventName)
    const result = filteredEvents.map((event) => event.identifier)
    return result
  }

  const removedCreationEvents = (creationEventName) => {
    const filteredEvents = timelineEventsData.filter((event) => event.name === creationEventName)
    const result = filteredEvents.map((event) => {
      const removed = checkRemovalEvent({ id: event.data.id, removalEventName: removalsEvents[creationEventName] })
      if (removed) {
        return { id: event.data.id, creationEventName: creationEventName }
      }
    })
    return result.filter(n => n)
  }
  const checkRemovalEvent = ({ id, removalEventName }) => {
    if (!removalEventName) { return false }

    const removed = timelineEventsData.filter((event) => event.name === removalEventName);
    if (!removed.length) { return false }
    const dataArray = pluck(removed, 'data')
    const ids = pluck(dataArray, 'id')
    return ids.includes(id)
  }

  const lastUpdates = (creationEventName) => {
    const filteredEvents = timelineEventsData.filter((event) => event.name === creationEventName)
    const result = filteredEvents.map((event) => {
      const updated = updateEvents({ id: event.data.id, creationEventName: creationEventName })
      const lastUpdatedOn = updated.length ? updated.map(event => event.date).sort().pop() : null
      if (lastUpdatedOn) {
        return { id: event.data.id, lastUpdatedOn: lastUpdatedOn }
      }
    })
    return result.filter(n => n)
  }

  const lastCreation = (creationEventName) => {
    const filteredEvents = timelineEventsData.filter((event) => event.name === creationEventName)
    return filteredEvents[0]
  }

  const updateEvents = ({ id, creationEventName }) => {
    const updateEventName = updatesEvents[creationEventName]
    if (!updateEventName) { return false }

    return timelineEventsData.filter((event) => event.name === updateEventName && event.data.id === id)
  }

  const creationEvent = ({ id, creationEventName }) => {
    return timelineEventsData.filter((event) => event.name === creationEventName && event.data.id === id)[0]
  }

  const removalsEvents = {
    'BonusRewardCreated': 'BonusRewardRemoved',
    'SalaryUpdateRequestCreated': 'SalaryUpdateRequestRemoved',
    'MealCreated': 'MealRemoved',
    'PsychologicalSupportCreated': 'PsychologicalSupportRemoved',
    'HomeOfficeCreated': 'HomeOfficeRemoved',
    'ExpenseAssistanceCreated': 'ExpenseAssistanceRemoved',
    'YearEndBonusCreated': 'YearEndBonusRemoved',
    'WorkLeaveCreated': 'WorkLeaveRemoved',
    "ParentalLeaveCreated": "ParentalLeaveRemoved",
    "VacationCreated": "VacationRemoved",
    'HealthcareCreated': 'HealthcareRemoved'
  }

  const updatesEvents = {
    'SalaryUpdateRequestCreated': 'SalaryUpdateRequestUpdated',
    'BonusRewardCreated': 'BonusRewardUpdated',
    'HomeOfficeCreated': 'HomeOfficeUpdated',
    'WorkLeaveCreated': 'WorkLeaveUpdated',
    'ExpenseAssistanceCreated': 'ExpenseAssistanceUpdated',
    'MealCreated': 'MealUpdated',
    'PsychologicalSupportCreated': 'PsychologicalSupportUpdated',
    'YearEndBonusCreated': 'YearEndBonusUpdated',
    'ParentalLeaveCreated': 'ParentalLeaveUpdated',
    'VacationCreated': 'VacationUpdated',
    'HealthcareCreated': 'HealthcareUpdated',
    'DismissalCreated': 'DismissalUpdated'
  }

  const eventsList = {
    "NewUserCreatedByAdmin": { name: NewUserCreatedByAdmin },
    "DismissalCreated": { name: DismissalCreated, lastUpdates: lastUpdates("DismissalCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'userDismissal') } },
    "DismissalUpdated": { name: DismissalUpdated, lastUpdates: lastUpdates("DismissalCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'userDismissal') } },
    "SalaryUpdateRequestCreated": { name: SalaryUpdateRequestCreated, removed: removedCreationEvents("SalaryUpdateRequestCreated"), lastUpdates: lastUpdates("SalaryUpdateRequestCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'salaryReadjustment') } },
    "SalaryUpdateRequestComplete": { name: SalaryUpdateRequestComplete },
    "SalaryUpdateRequestRemoved": { name: SalaryUpdateRequestRemoved },
    "SalaryUpdateRequestUpdated": { name: SalaryUpdateRequestUpdated, removed: removedCreationEvents("SalaryUpdateRequestCreated"), lastUpdates: lastUpdates("SalaryUpdateRequestCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'salaryReadjustment') } },
    "UserRegisterUpdatedByAdmin": { name: UserRegisterUpdated },
    "UserRegisterUpdatedByRebaser": { name: UserRegisterUpdated },
    "ExpenseAssistanceCreated": { name: ExpenseAssistanceCreated, removed: removedCreationEvents("ExpenseAssistanceCreated"), lastUpdates: lastUpdates("ExpenseAssistanceCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'expenseAssistance') } },
    "ExpenseAssistanceRemoved": { name: ExpenseAssistanceRemoved },
    "ExpenseAssistanceUpdated": { name: ExpenseAssistanceUpdated, removed: removedCreationEvents("ExpenseAssistanceCreated"), lastUpdates: lastUpdates("ExpenseAssistanceCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'expenseAssistance') } },
    "ParentalLeaveCreated": { name: ParentalLeaveCreated, removed: removedCreationEvents("ParentalLeaveCreated"), lastUpdates: lastUpdates("ParentalLeaveCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'parentalLeave') } },
    "ParentalLeaveUpdated": { name: ParentalLeaveUpdated, removed: removedCreationEvents("ParentalLeaveCreated"), lastUpdates: lastUpdates("ParentalLeaveCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'parentalLeave') } },
    "ParentalLeaveStart": { name: ParentalLeaveStart },
    "ParentalLeaveReturn": { name: ParentalLeaveReturn },
    "ParentalLeaveRemoved": { name: ParentalLeaveRemoved },
    "BonusRewardCreated": { name: BonusRewardCreated, removed: removedCreationEvents("BonusRewardCreated"), lastUpdates: lastUpdates("BonusRewardCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'bonus') } },
    "BonusRewardRemoved": { name: BonusRewardRemoved },
    "BonusRewardUpdated": { name: BonusRewardUpdated, removed: removedCreationEvents("BonusRewardCreated"), lastUpdates: lastUpdates("BonusRewardCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'bonus') } },
    "VacationCreated": { name: VacationCreated, removed: removedCreationEvents("VacationCreated"), lastUpdates: lastUpdates("VacationCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'vacation') } },
    "VacationUpdated": { name: VacationUpdated, removed: removedCreationEvents("VacationCreated"), lastUpdates: lastUpdates("VacationCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'vacation') } },
    "VacationStart": { name: VacationStart },
    "VacationReturn": { name: VacationReturn },
    "VacationRemoved": { name: VacationRemoved },
    "VacationCreditPublished": { name: VacationCreditPublished },
    "HomeOfficeCreated": { name: HomeOfficeCreated, identifiers: filteredIdentifiers("HomeOfficeCreated"), removed: removedCreationEvents("HomeOfficeCreated"), lastUpdates: lastUpdates("HomeOfficeCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'homeOffice') } },
    "HomeOfficeRemoved": { name: HomeOfficeRemoved },
    "HomeOfficeUpdated": { name: HomeOfficeUpdated, removed: removedCreationEvents("HomeOfficeCreated"), lastUpdates: lastUpdates("HomeOfficeCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'homeOffice') } },
    "MealCreated": { name: MealCreated, identifiers: filteredIdentifiers("MealCreated"), removed: removedCreationEvents("MealCreated"), lastUpdates: lastUpdates("MealCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'meal') } },
    "MealRemoved": { name: MealRemoved },
    "MealUpdated": { name: MealUpdated, removed: removedCreationEvents("MealCreated"), lastUpdates: lastUpdates("MealCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'meal') } },
    "PsychologicalSupportCreated": { name: PsychologicalSupportCreated, identifiers: filteredIdentifiers("PsychologicalSupportCreated"), removed: removedCreationEvents("PsychologicalSupportCreated"), lastUpdates: lastUpdates("PsychologicalSupportCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'psychologicalSupport') } },
    "PsychologicalSupportUpdated": { name: PsychologicalSupportUpdated, removed: removedCreationEvents("PsychologicalSupportCreated"), lastUpdates: lastUpdates("PsychologicalSupportCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'psychologicalSupport') } },
    "PsychologicalSupportRemoved": { name: PsychologicalSupportRemoved },
    "YearEndBonusCreated": { name: YearEndBonusCreated, removed: removedCreationEvents("YearEndBonusCreated"), lastUpdates: lastUpdates("YearEndBonusCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'yearEndBonus') } },
    "YearEndBonusUpdated": { name: YearEndBonusUpdated, removed: removedCreationEvents("YearEndBonusCreated"), lastUpdates: lastUpdates("YearEndBonusCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'yearEndBonus') } },
    "YearEndBonusRemoved": { name: YearEndBonusRemoved },
    "HealthcareCreated": { name: HealthcareCreated, identifiers: filteredIdentifiers("HealthcareCreated"), removed: removedCreationEvents("HealthcareCreated"), lastUpdates: lastUpdates("HealthcareCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'healthcare') } },
    "HealthcareUpdated": { name: HealthcareUpdated, identifiers: filteredIdentifiers("HealthcareCreated"), removed: removedCreationEvents("HealthcareCreated"), lastUpdates: lastUpdates("HealthcareCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'healthcare') } },
    "HealthcareRemoved": { name: HealthcareRemoved },
    "HealthcareCreditPublished": { name: HealthcareCreditPublished },
    "StartedInCompany": { name: StartedInCompany },
    "WorkLeaveCreated": { name: WorkLeaveCreated, removed: removedCreationEvents("WorkLeaveCreated"), lastUpdates: lastUpdates("WorkLeaveCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'workLeave') } },
    "WorkLeaveUpdated": { name: WorkLeaveUpdated, removed: removedCreationEvents("WorkLeaveCreated"), lastUpdates: lastUpdates("WorkLeaveCreated"), openEditionDrawer: () => { handleOpenedDrawersFromProfile(userData, 'workLeave') } },
    "WorkLeaveRemoved": { name: WorkLeaveRemoved },
    "WorkLeaveStart": { name: WorkLeaveStart },
    "WorkLeaveReturn": { name: WorkLeaveReturn },
    // Add each event name as key to eventsList
  }

  const removeObject = (eventName) => {
    eventsListRemovals[eventName]?.removal?.()
      .then(() => {
        setOpenRemovalConfirmation(false)
        setRefreshUserData(!refreshUserData)
        setShowDetails('close')
        const removalSuccessMessageSource = eventsListRemovals[chosenEventRemoval?.name]?.removalSource
        const newMessage = {
          success: true,
          source: removalSuccessMessageSource,
        }
        if (removalSuccessMessageSource === 'bonus_reward_removed') {
          newMessage['additionalData'] = i18n.t(`drawerResponses.success.bonus_reward_removed.rewardType.${chosenEventRemoval?.data?.reward_type}`)
        }
        setShowMessage(messages => [newMessage, ...messages])
        setObjectId(null)
      })
  }

  const cancelFunction = () => {
    setOpenRemovalConfirmation(false)
    setObjectId(null)
    setChosenEventRemoval(null)
    setShowDetails(null)
  }

  const eventsListRemovals = {
    "BonusRewardCreated": {
      removal: () => removeBonusReward({ user: userData, id: objectId }),
      title: 'timelineEvents.bonusRewardCreated.confirmation.title',
      text: 'timelineEvents.bonusRewardCreated.confirmation.text',
      removalSource: 'bonus_reward_removed'
    },
    "MealCreated": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'meal' }),
      title: 'timelineEvents.mealCreated.confirmation.title',
      text: 'timelineEvents.mealCreated.confirmation.text',
      removalSource: 'meal_allowance_removed'
    },
    "PsychologicalSupportCreated": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'psychological_support' }),
      title: 'timelineEvents.psychologicalAssistanceCreated.confirmation.title',
      text: 'timelineEvents.psychologicalAssistanceCreated.confirmation.text',
      removalSource: 'psychological_support_removed'
    },
    "HomeOfficeCreated": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'home_office' }),
      title: 'timelineEvents.homeOfficeCreated.confirmation.title',
      text: 'timelineEvents.homeOfficeCreated.confirmation.text',
      removalSource: 'home_office_allowance_removed'
    },
    "HealthcareCreated": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'healthcare' }),
      title: 'timelineEvents.healthcareCreated.confirmation.title',
      text: 'timelineEvents.healthcareCreated.confirmation.text',
      removalSource: 'healthcare_removed'
    },
    "ExpenseAssistanceCreated": {
      removal: () => removeExpenseAssistance({ user: userData, id: objectId }),
      title: 'timelineEvents.expenseAssistanceCreated.confirmation.title',
      text: 'timelineEvents.expenseAssistanceCreated.confirmation.text',
      removalSource: 'expense_assistance_removed'
    },
    "SalaryUpdateRequestCreated": {
      removal: () => removeSalaryUpdateRequest({ user: userData, id: objectId }),
      title: 'timelineEvents.salaryUpdateRequestCreated.confirmation.title',
      text: 'timelineEvents.salaryUpdateRequestCreated.confirmation.text',
      removalSource: 'salary_readjustment_removed'
    },
    "YearEndBonusCreated": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'year_end_bonus' }),
      title: 'timelineEvents.yearEndBonusCreated.confirmation.title',
      text: 'timelineEvents.yearEndBonusCreated.confirmation.text',
      removalSource: 'year_end_bonus_removed'
    },
    "WorkLeaveCreated": {
      removal: () => removeWorkLeave({ user: userData, id: objectId }),
      title: 'timelineEvents.workLeaveCreated.confirmation.title',
      text: 'timelineEvents.workLeaveCreated.confirmation.text',
      removalSource: 'work_leave_removed'
    },
    "ParentalLeaveCreated": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'parental_leave' }),
      title: 'timelineEvents.parentalLeaveCreated.confirmation.title',
      text: 'timelineEvents.parentalLeaveCreated.confirmation.text',
      removalSource: 'parental_leave_removed'
    },
    "VacationCreated": {
      removal: () => removeAllowance({ user: userData, id: objectId, allowanceName: 'vacation' }),
      title: 'timelineEvents.vacationCreated.confirmation.title',
      text: 'timelineEvents.vacationCreated.confirmation.text',
      removalSource: 'vacation_removed'
    },
  }

  useEffect(() => {
    userData.dismissal_id && setIsDismissed(true)
  }, []);

  return (
    <>
      {groupedByDate.map((dateGroup, index) => (
        <section key={index} className="items-center">
          <div className="justify-center px-5 mx-0 mb-1.5 md:px-6">
            <div className="w-full mx-0">
              <div className="relative flex left-0">
                <div className="flex flex-col items-center w-6">
                  <div className="mt-1">
                    <div className="flex items-center justify-center w-5 h-5 bg-rebase-blue rounded-full dark:bg-timeline-gray">
                      <div className="w-3.5 h-3.5 rounded-full bg-rebase-blue" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="relative flex-1 bg-white ml-4 ">
                    <span
                      className="inline-block px-2.5 text-info-gray rounded-3xl border border-timeline-light-gray font-inter text-base font-normal leading-6">
                      {dateGroup.date}
                    </span>
                  </div>
                </div>
              </div>

              <div className="relative flex mt-2 left-0 w-[98%]">
                <div className="flex flex-col items-center w-0.5 relative left-2.5">
                  <div className="w-0.5 h-full bg-timeline-gray" />
                </div>
                <div className='relative left-5 w-full'>
                  {dateGroup.events.reverse().map((eventData, index) => {
                    const EventComponent = eventsList[eventData.name]?.name
                    if (EventComponent) {
                      return (
                        <div key={index}>
                          <EventComponent
                            eventData={eventData}
                            userData={userData}
                            identifiersArray={eventsList[eventData.name].identifiers}
                            lastCreation={lastCreation}
                            removedArray={eventsList[eventData.name].removed}
                            lastUpdates={eventsList[eventData.name].lastUpdates}
                            updateEvents={updateEvents}
                            creationEvent={creationEvent}
                            setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                            setObjectId={setObjectId}
                            setChosenEventRemoval={setChosenEventRemoval}
                            showDetails={showDetails}
                            setShowDetails={setShowDetails}
                            openEditionDrawer={() => eventsList[eventData.name]?.openEditionDrawer?.()}
                            isDismissed={isDismissed}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
      {openRemovalConfirmation &&
        <RemovalConfirmation
          cancelFunction={cancelFunction}
          removalFunction={() => removeObject(chosenEventRemoval?.name)}
          userData={userData}
          i18nTitle={eventsListRemovals[chosenEventRemoval?.name]?.title}
          i18nText={eventsListRemovals[chosenEventRemoval?.name]?.text}
        />
      }
    </>
  )
}
