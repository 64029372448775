import { useState } from "react";

export default function useDrawersSettings() {
  const [selectedPerson, setSelectedPerson] = useState('')

  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);
  const [bonusDrawerOpen, setBonusDrawerOpen] = useState(false);
  const [addAllowancesDrawerOpen, setAddAllowancesDrawerOpen] = useState(false);
  const [expenseAssistanceDrawerOpen, setExpenseAssistanceDrawerOpen] = useState(false);
  const [salaryReadjustmentDrawerOpen, setSalaryReadjustmentDrawerOpen] = useState(false);
  const [workLeaveDrawerOpen, setWorkLeaveDrawerOpen] = useState(false)
  const [userDismissalDrawerOpen, setUserDismissalDrawerOpen] = useState(false)

  const [profileTabName, setProfileTabName] = useState('')
  const [chosenAllowanceName, setChosenAllowanceName] = useState('')
  const [objectId, setObjectId] = useState()

  const openAllowanceDrawer = ({ benefitName, status }) => {
    setAddAllowancesDrawerOpen(status)
    setChosenAllowanceName(benefitName)
  }

  const anyFormsDrawerOppened = () => {
    return bonusDrawerOpen || addAllowancesDrawerOpen || expenseAssistanceDrawerOpen ||
           salaryReadjustmentDrawerOpen || workLeaveDrawerOpen || userDismissalDrawerOpen
  }

  let setOpenedDrawers = {
    bonus: setBonusDrawerOpen,
    expenseAssistance: setExpenseAssistanceDrawerOpen,
    allowances: setAddAllowancesDrawerOpen,
    salaryReadjustment: setSalaryReadjustmentDrawerOpen,
    profile: setProfileDrawerOpen,
    workLeave: setWorkLeaveDrawerOpen,
    userDismissal: setUserDismissalDrawerOpen,
    homeOffice: (status) => openAllowanceDrawer({ benefitName: 'homeOffice', status: status }),
    meal: (status) => openAllowanceDrawer({ benefitName: 'meal', status: status }),
    psychologicalSupport: (status) => openAllowanceDrawer({ benefitName: 'psychological_support', status: status }),
    yearEndBonus: (status) => openAllowanceDrawer({ benefitName: 'year_end_bonus', status: status }),
    parentalLeave: (status) => openAllowanceDrawer({ benefitName: 'parental_leave', status: status }),
    vacation: (status) => openAllowanceDrawer({ benefitName: 'vacation', status: status }),
    healthcare: (status) => openAllowanceDrawer({ benefitName: 'healthcare', status: status }),
  }

  let openedDrawers = {
    bonus: bonusDrawerOpen,
    expenseAssistance: expenseAssistanceDrawerOpen,
    allowances: addAllowancesDrawerOpen,
    salaryReadjustment: salaryReadjustmentDrawerOpen,
    profile: profileDrawerOpen,
    workLeave: workLeaveDrawerOpen,
    userDismissal: userDismissalDrawerOpen,
  }

  function handleDrawersOpen(person, drawer) {
    setSelectedPerson(person)
    setOpenedDrawers[drawer] ? setOpenedDrawers[drawer](true) : null
    document.body.style.overflow = "hidden";
  }

  function handleDrawersClose({ drawersToClose = [], clearData = true }) {
    if (drawersToClose.length) {
      drawersToClose.forEach((drawer) => {
        setOpenedDrawers[drawer] ? setOpenedDrawers[drawer](false) : null
      })
    } else {
      setProfileDrawerOpen(false);
      setBonusDrawerOpen(false)
      setExpenseAssistanceDrawerOpen(false)
      setAddAllowancesDrawerOpen(false)
      setSalaryReadjustmentDrawerOpen(false)
      setWorkLeaveDrawerOpen(false)
      setUserDismissalDrawerOpen(false)
    }
    if (clearData) {
      setSelectedPerson({});
    }
    setObjectId(null)
    setChosenAllowanceName('')
    document.body.style.overflow = "auto";
  }

  return {
    openedDrawers,
    profileTabName,
    setProfileTabName,
    handleDrawersOpen,
    handleDrawersClose,
    objectId,
    setObjectId,
    chosenAllowanceName,
    setChosenAllowanceName,
    selectedPerson,
    setSelectedPerson,
    anyFormsDrawerOppened,
  };
}
