const translations = {
  add_allowances: {
    title: "Adicionar benefício",
    subtitle: "Adicione um benefício para ",
    select_names_title: "Selecione o benefício",
    submit_button_text: "Adicionar benefício",
    select_allowance: "Selecione uma das opções",
    home_office: "Auxílio home office",
    healthcare: "Prêmio saúde",
    meal: "Auxílio alimentação",
    year_end_bonus: "Bônus de fim de ano",
    parental_leave: "Presença parental",
    vacation: "Descanso remunerado",
    psychological_support: "Auxílio psicológico",
    disabled: "Já possui esse benefício",
    out_of_days: "Já utilizou todos os dias no período",
    forms_error: {
      presence: "O preenchimento deste campo é obrigatório",
    }
  },
  update_allowances: {
    title: "Editar benefício",
    subtitle: "Edite o benefício para ",
    submit_button_text: "Salvar edição",
  },
  home_office_allowance: {
    monthly_value: "Valor mensal",
    notes: "Observações",
    notes_placeholder: "Se houver, adicione informações relevantes sobre este benefício",
    forms_error: {
      value_number: "Deve ser um número",
    },
  },
  psychological_support: {
    monthly_sessions: "Quantidade de sessões mensais",
    notes: "Observações",
    sessions_start_date: "Mês de início das sessões",
    duration: {
      title: 'Duração da recorrência',
      undetermined_time: "Tempo indeterminado",
      2: "2 meses",
      3: "3 meses",
      4: "4 meses",
      5: "5 meses",
      6: "6 meses",
      7: "7 meses",
      8: "8 meses",
      9: "9 meses",
      10: "10 meses",
      11: "11 meses",
      12: "12 meses",
      24: "24 meses",
    },
    notes_placeholder: "Se houver, adicione informações relevantes sobre este benefício",
    messageForm: {
      success: "Benefício adicionado",
      successDataCreated_1: "O",
      successDataCreated_2: "Auxílio Psicológico",
      successDataCreated_3: "foi concedido com sucesso para",
    },
    forms_error: {
      presence: "O preenchimento deste campo é obrigatório",
      sessions_start_date: "Não pode ser no passado",
    },
  },
  meal_allowance: {
    monthly_value: "Valor mensal",
    notes: "Observações",
    notes_placeholder: "Se houver, adicione informações relevantes sobre este benefício",
    messageForm: {
      success: "Benefício adicionado",
      successDataCreated_1: "O",
      successDataCreated_2: "Auxílio alimentação",
      successDataCreated_3: "foi concedido com sucesso para",
    },
    forms_error: {
      value_number: "Deve ser um número",
    },
  },
  parental_leave: {
    start_date: "Data de início",
    return_date: "Data de retorno",
    notes: "Observações",
    notes_placeholder: "Se houver, adicione informações relevantes sobre este benefício",
    messageForm: {
      success: "Benefício adicionado",
      successDataCreated_1: "O benefício",
      successDataCreated_2: "Presença parental",
      successDataCreated_3: "foi concedido com sucesso para",
      error: {
        basic_server_error: "Preenchimento inválido",
        start_date: {
          'já_está_em_presença_parental': 'já possui um beneficio presença parental neste intervalo de datas.',
        }
      }
    },
    forms_error: {
      time_interval: "Período inválido, já incluso em licença prévia",
      presence: "O preenchimento deste campo é obrigatório",
      date_error: "A data de início não pode ser anterior à data de hoje",
    },
  },
  healthcare: {
    title_person: "Nome do beneficiário",
    title_value: "Valor do Benefício",
    total_value: "Valor mensal",
    image: {
      alt: {
        card_image: "Foto da carterinha",
      },
    },
    messageForm: {
      success: "Benefício adicionado",
      successDataCreated_1: "O",
      successDataCreated_2: "Prêmio saúde",
      successDataCreated_3: "foi concedido com sucesso para",
      error: {
        missing_proofs: {
          é_necessário_carterinhas_para_todos_os_beneficiários: 'Número de comprovantes incorreto, deve haver um comprovante para cada beneficiário'
        }
      }
    },
    forms_error: {
      presence: "O preenchimento deste campo é obrigatório",
    },
  },
}

export { translations }
