import React from 'react'
import i18n from "#translate/i18n"
import mealIcon from '#images/events_icons/meal.svg';
import moneyIcon from '#images/profile_drawer/money-icon.svg';

import OptionsMenu from '#components/user/profile/remunerationTab/OptionsMenu';
export default function ActiveMeal({ 
  allowanceData,
  setOpenRemovalConfirmation,
  setObjectId,
  setChosenObjectRemoval,
  handleOpenedDrawersFromProfile,
  userData,
  afterDismissal
}) {

  return (
    <div id='user-active-meal' className='w-full py-4 px-0 rounded border border-solitude gap-4 grid' >
      <div className='py-0 px-4 gap-3 flex' >
        <div className='gap-3 flex w-full' >
          <div className='h-[50px] w-[50px] flex justify-center items-center mt-auto mb-auto' >
            <div className='rounded-full shadow-timeline-icons'>
              <img
                src={mealIcon}
                alt="event icon"
                className='w-11 h-11 p-1'
              />
            </div>
          </div>
          <div className='w-[90%]'>
            <p className='font-sans text-lg/[30px] font-semibold text-left text-midnight w-[90%] absolute' >
              {i18n.t('userProfile.remuneration_tab.active_allowances.meal.title')}
            </p>
            <OptionsMenu
              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
              setObjectId={setObjectId}
              setChosenObjectRemoval={setChosenObjectRemoval}
              chosenObjectData={allowanceData}
              objectType='ActiveMeal'
              afterDismissal={afterDismissal}
              isUpdatable={true}
              isRemovable={true}
              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
              userData={userData}
            />
            <div className='flex gap-2 items-center mt-8' >
              <img src={moneyIcon} alt='money_image' />
              <p className='font-sans text-sm/6 font-normal text-left text-soft-light-gray' >
                {Number(allowanceData.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
