import React, { useState, useEffect, useContext } from 'react';
import activeAllowancesRequest from '#requests/userProfile/activeAllowancesRequest';
import ActiveMeal from '#components/user/profile/remunerationTab/ActiveMeal';
import ActiveHomeOffice from '#components/user/profile/remunerationTab/ActiveHomeOffice';
import ActiveYearEndBonus from '#components/user/profile/remunerationTab/ActiveYearEndBonus';
import ActiveVacation from '#components/user/profile/remunerationTab/ActiveVacation';
import ActiveHealthcares from '#components/user/profile/remunerationTab/ActiveHealthcares';
import ActiveParentalLeave from '#components/user/profile/remunerationTab/ActiveParentalLeave';
import ActivePsychologicalSupport from '#components/user/profile/remunerationTab/ActivePsychologicalSupport';
import i18n from "#translate/i18n";
import { UserContext } from "#providers/UserContextProvider"

export default function ActiveAllowances({
  userData,
  setOpenRemovalConfirmation,
  setChosenObjectRemoval,
  handleOpenedDrawersFromProfile,
}) {

  const { setObjectId, refreshUserData } = useContext(UserContext)
  const [userActiveAllowances, setUserActiveAllowances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [afterDismissal, setAfterDismissal] = useState(false)

  const emptyAllowance = () => {
    const { meal, home_office, year_end_bonus, vacations, healthcare_collection,
      parental_leaves, psychological_support
    } = userActiveAllowances;

    return [meal, home_office, year_end_bonus, vacations, healthcare_collection, parental_leaves, psychological_support]
      .every(allowance => {
        if (Array.isArray(allowance)) {
          return allowance.length === 0
        }
        return !allowance
      });
  }

  useEffect(() => {
    userData.dismissal_id && setAfterDismissal(true)
    activeAllowancesRequest({ userId: userData.id })
      .then((response) => response.json())
      .then((parsedResponse) => {
        setUserActiveAllowances(parsedResponse)
        setLoading(false)
      })
  }, [userData, refreshUserData]);

  return (
    <>
      {
        !loading && emptyAllowance() ?
          <div className='text-info-gray p-6 text-center'>
            {i18n.t('userProfile.remuneration_tab.active_allowances.no_active_allowances')}
          </div> :
          <section className="gap-4 grid grid-cols-2 py-5 px-0 my-0 mx-5" id='user-active-allowances' >
            { userActiveAllowances.meal && <ActiveMeal
                                              allowanceData={userActiveAllowances.meal}
                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                              setObjectId={setObjectId}
                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                              userData={userData}
                                              afterDismissal={afterDismissal}
                                            />
            }
            { userActiveAllowances.home_office && <ActiveHomeOffice
                                                    allowanceData={userActiveAllowances.home_office}
                                                    setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                    setObjectId={setObjectId}
                                                    setChosenObjectRemoval={setChosenObjectRemoval}
                                                    handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                    userData={userData}
                                                    afterDismissal={afterDismissal}
                                                  />
            }
            { userActiveAllowances.year_end_bonus && <ActiveYearEndBonus
                                                      allowanceData={userActiveAllowances.year_end_bonus}
                                                      setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                      setObjectId={setObjectId}
                                                      setChosenObjectRemoval={setChosenObjectRemoval}
                                                      handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                      userData={userData}
                                                    />
            }
            { userActiveAllowances.vacations?.map(vacation => <>
                                                                <ActiveVacation
                                                                  allowanceData={vacation}
                                                                  setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                                  setObjectId={setObjectId}
                                                                  setChosenObjectRemoval={setChosenObjectRemoval}
                                                                  handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                                  userData={userData}
                                                                />
                                                              </>
                                                    )
            }
            { userActiveAllowances.healthcare_collection && <ActiveHealthcares
                                                              allowanceData={userActiveAllowances.healthcare_collection}
                                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                              setObjectId={setObjectId}
                                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                              userData={userData}
                                                              afterDismissal={afterDismissal}
                                                            />
            }
            { userActiveAllowances.parental_leaves?.map(parentalLeave => <>
                                                                            <ActiveParentalLeave
                                                                              allowanceData={parentalLeave}
                                                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                                              setObjectId={setObjectId}
                                                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                                              userData={userData}
                                                                            />
                                                                          </>
                                                        )
            }
            { userActiveAllowances.psychological_support && <ActivePsychologicalSupport
                                                              allowanceData={userActiveAllowances.psychological_support}
                                                              setOpenRemovalConfirmation={setOpenRemovalConfirmation}
                                                              setObjectId={setObjectId}
                                                              setChosenObjectRemoval={setChosenObjectRemoval}
                                                              handleOpenedDrawersFromProfile={handleOpenedDrawersFromProfile}
                                                              userData={userData}
                                                              afterDismissal={afterDismissal}
                                                            />
            }
          </section>
      }
    </>
  )
}
